<template>
  <div>
    <header-top-dashboard primary></header-top-dashboard>
    <v-container fluid class="pt-0 px-6 mt-n16">
      <v-row>
        <v-col cols="12" class="mt-n3">
          <v-card>
            <v-tabs v-model="tab" icons-and-text fixed-tabs>
              <v-tabs-slider></v-tabs-slider>
              <v-tab
                v-for="(tab, index) in tabHeadings"
                :key="'deliveries-tab-' + index"
                :href="`#tab_${tab.anchor}`"
              >
                <v-badge
                  v-if="tabItems[tab.id].length > 0"
                  color="red"
                  :content="tabItems[tab.id].length"
                >
                  {{ tab.title }}
                </v-badge>
                <span v-else>
                  {{ tab.title }}
                </span>
              </v-tab>
            </v-tabs>

            <v-tabs-items v-model="tab">
              <v-tab-item
                v-for="(tab, index) in tabHeadings"
                :key="'deliveries-tab-items-' + index"
                :value="`tab_${tab.anchor}`"
              >
                <simple-table
                  :show-create-new-item-button="false"
                  :enabled-action-edit="tab.enableActionEdit"
                  :enabled-action-deactivate="tab.enableActionDeactivate"
                  :enabled-action-delete="tab.enableActionDelete"
                  :enable-action-go-to-item-page="tab.enableActionGoToItemPage"
                  :enabled-action-approve="tab.enableActionApprove"
                  :approve-tooltip="tab.approveTooltip"
                  :approve-icon="tab.approveIcon"
                  :approve-confirmation-text="tab.approveConfirmationText"
                  :headers="headers"
                  :items="tabItems[tab.id]"
                  :pagination-details="paginationDetails"
                  no-data-text="No data"
                  :showSearchBox="true"
                  :searchPlaceholder="searchPlaceholder"
                  :showFilterBy="showAdvancedFilters"
                  :filterByIdentifier="'driver'"
                  :filterByPlaceholder="filterByPlaceholder"
                  :filterbyItems="driverUserList"
                  :filterbyItemText="'friendlyName'"
                  :filterbyItemValue="'driverId'"
                  @updateFilterBy="updateSearchParameters"
                  :showFilterByDate="showAdvancedFilters"
                  :filterByDatePlaceholder="filterByDatePlaceholder"
                  :show-total-value="true"
                  :total-value-text="`Total Expected CoD Value`"
                  :total-value="tabItemsTotals[tab.id]"
                  :sub-total-value-text="`Total Collected CoD Value`"
                  :sub-total-value="tabItemsTotalsCollected[tab.id]"
                  @clearFilters="clearFilters"
                  @updateSearchValue="updateShopSearchParam"
                  @approveItem="approveItem"
                  @createNewItem="createNewItem"
                  @editItem="editItem"
                  @fetchNextPage="fetchDataByUrl(paginationDetails.nextPageUrl)"
                  @fetchPreviousPage="
                    fetchDataByUrl(this.paginationDetails.previousPageUrl)
                  "
                  @updateItemsPerPage="updateItemsPerPage"
                  @updateSelectedPage="updateSelectedPage"
                ></simple-table>
              </v-tab-item>
            </v-tabs-items>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import { mapStores } from "pinia";
import { useSessionStore } from "@/store/session";
import HeaderTopDashboard from "@/components/HeaderTopDashboard.vue";
import SimpleTable from "@/components/Tables/SimpleTable.vue";
export default {
  name: "deliveries",
  components: {
    HeaderTopDashboard,
    SimpleTable,
  },
  data() {
    return {
      baseUrl: "/deliveries?size=200",
      dataLoadInterval: null,
      fullItems: [],
      headers: [
        {
          text: "Order #",
          align: "start",
          sortable: false,
          value: "orderReference",
        },
        { text: "Shop", value: "shop" },
        { text: "Value", value: "value" },
        { text: "Collected Value", value: "collectedValue" },
        { text: "Credit Order", value: "isCreditOrder" },
        { text: "Delivery Date", value: "deliveryDate" },
        { text: "Status", value: "status" },
        { text: "Driver", value: "driver" },
        { text: "Actions", value: "actions", sortable: false },
      ],
      items: [],
      paginationDetails: {
        page: 1,
        pageCount: 0,
        itemsPerPage: 40,
        pageStart: 0,
        pageStop: 0,
        itemsLength: 0,
        firstPageUrl: "",
        lastPageUrl: "",
        nextPageUrl: "",
        previousPageUrl: "",
        links: [],
        path: "",
      },
      // tab: null,
      tabHeadings: [
        {
          id: "reviewAssignDriver",
          title: "Review & Assign Driver",
          icon: "cart-arrow-right",
          enableActionEdit: true,
          enableActionDeactivate: false,
          enableActionDelete: false,
          enableActionGoToItemPage: false,
          enableActionApprove: true,
        },
        {
          id: "awaitingDelivery",
          title: "Awaiting Delivery",
          icon: "cart-arrow-right",
          enableActionEdit: true,
          enableActionDeactivate: false,
          enableActionDelete: false,
          enableActionGoToItemPage: false,
          enableActionApprove: false,
        },
        {
          id: "inTransit",
          title: "In Transit",
          icon: "cart-arrow-right",
          enableActionEdit: true,
          enableActionDeactivate: false,
          enableActionDelete: false,
          enableActionGoToItemPage: false,
          enableActionApprove: false,
        },
        {
          id: "deliveredAndComplete",
          title: "Delivered Complete",
          icon: "cart-arrow-right",
          enableActionEdit: true,
          enableActionDeactivate: false,
          enableActionDelete: false,
          enableActionGoToItemPage: false,
          enableActionApprove: false,
        },
        {
          id: "notDelivered",
          title: "Not Delivered",
          icon: "cart-arrow-right",
          enableActionEdit: true,
          enableActionDeactivate: false,
          enableActionDelete: false,
          enableActionGoToItemPage: false,
          enableActionApprove: true,
        },
        {
          id: "cancelled",
          title: "Cancelled",
          icon: "cart-arrow-right",
          enableActionEdit: true,
          enableActionDeactivate: false,
          enableActionDelete: false,
          enableActionGoToItemPage: false,
          enableActionApprove: true,
        },
      ],
      tabItems: {
        reviewAssignDriver: [],
        awaitingDelivery: [],
        inTransit: [],
        deliveredAndComplete: [],
        notDelivered: [],
        cancelled: [],
      },
      tabItemsTotals: {},
      tabItemsTotalsCollected: {},
      // searchValue: "",
      driverList: [],
      driverUserList: [],
      searchFilterParameters: [],
      filterByShopParams: {
        field: "shop",
        operator: "",
        value: null,
      },
      filterByDriverParams: {
        field: "driver",
        operator: "",
        value: null,
      },
      filterByDateParams: {
        field: "delivery_date",
        operator: "",
        value: null,
      },
    };
  },
  computed: {
    ...mapStores(useSessionStore),

    tab: {
      set(tab) {
        this.$router.replace({ query: { ...this.$route.query, tab } });
      },
      get() {
        return this.$route.query.tab;
      },
    },

    getSearchFilterParams() {
      const result = [];

      if (this.filterByShopParams.value) {
        result.push(this.filterByShopParams);
      }

      if (this.filterByDriverParams.value) {
        result.push(this.filterByDriverParams);
      }

      if (this.filterByDateParams.value) {
        result.push(this.filterByDateParams);
      }

      return result;
    },

    searchPlaceholder() {
      if (this.filterByShopParams.value) {
        return this.filterByShopParams.value;
      }

      return "Search order number or shop name...";
    },

    filterByPlaceholder() {
      if (this.filterByDriverParams.value) {
        const driverObj = this.driverUserList.find(
          (d) => d.driverId === this.filterByDriverParams.value
        );
        return driverObj.friendlyName;
      }

      return "Filter by driver...";
    },

    filterByDatePlaceholder() {
      if (this.filterByDateParams.value) {
        return this.filterByDateParams.value;
      }

      return "Filter by delivery date...";
    },

    showAdvancedFilters() {
      if (
        this.sessionStore.user.roles &&
        (this.sessionStore.user.roles.includes("super_admin") ||
          this.sessionStore.user.roles.includes("admin") ||
          this.sessionStore.user.roles.includes("agent") ||
          this.sessionStore.user.roles.includes("warehouse_manager"))
      ) {
        return true;
      }

      return false;
    },
  },
  methods: {
    approveItem(itemId) {
      const item = this.fullItems.find((obj) => obj.id === itemId);

      let newStatus = "";

      if (item.status === "review_and_assign_driver") {
        newStatus = "awaiting_delivery";
      } else if (item.status === "awaiting_delivery") {
        newStatus = "in_transit";
      } else if (item.status === "in_transit") {
        if (item.payment_type === "cash_on_delivery") {
          newStatus = "awaiting_payment";
        } else {
          newStatus = "delivered";
        }
      }

      if (!item.driver_id || !item.delivery_date) {
        this.$notify.warning(
          "Please assign a driver and a delivery date first."
        );
        return;
      }

      if (newStatus !== "") {
        const body = {
          status: newStatus,
        };

        this.$http
          .patch(
            `/deliveries/${item.id}`,
            body,
            this.sessionStore.getHttpConfig
          )
          .then(() => {
            this.fetchDataByUrl(this.baseUrl);
            this.$notify.success(
              `Order successfully moved to "${newStatus}" status.`
            );
          })
          .catch((error) => {
            this.handleHttpError(
              error,
              "An error occurred while approving the order."
            );
          });
      }
    },

    createNewItem() {
      this.$router.push("/deliveries/create");
    },

    editItem(item) {
      this.$router.push({
        name: "Edit Delivery",
        params: {
          id: `${item.id}`,
        },
      });
    },

    fetchDataByUrl(url) {
      if (!url) {
        // TODO - print alert.
        return;
      }

      if (!url.includes("search?")) {
        this.baseUrl = "/deliveries?size=200";
        this.paginationDetails.loading = true;
        this.$http
          .get(url, this.sessionStore.getHttpConfig)
          .then((result) => {
            this.items.splice(0);
            this.fullItems.splice(0);
            this.tabItems.reviewAssignDriver.splice(0);
            this.tabItems.awaitingDelivery.splice(0);
            this.tabItems.inTransit.splice(0);
            this.tabItems.deliveredAndComplete.splice(0);
            this.tabItems.notDelivered.splice(0);
            this.tabItems.cancelled.splice(0);

            for (let i = 0; i < result.data.data.length; i++) {
              const delivery = result.data.data[i];
              this.fullItems.push(delivery);

              const tempTotal =
                Number(delivery.order.total) +
                Number(delivery.order.delivery_fee);
              const total =
                Math.round((tempTotal + Number.EPSILON) * 100) / 100;

              const collectedTotal =
                Math.round(
                  (Number(delivery.order.collected_total) + Number.EPSILON) *
                    100
                ) / 100;

              let driverName = "N/A";
              if (delivery.driver && delivery.driver.user) {
                driverName = `${delivery.driver.user.firstname} ${delivery.driver.user.lastname}`;
              }

              const tmpObj = {
                id: delivery.id,
                orderReference: delivery.order.order_reference,
                shop: delivery.order.shop.name,
                value: total.toString(),
                collectedValue: collectedTotal.toString(),
                deliveryDate: delivery.delivery_date,
                status: this.deliveryStatuses.find(
                  (element) => element.key == delivery.status
                ).value,
                payment_type: delivery.order.payment_type,
                driver: driverName,
                isCreditOrder: delivery.order.tags === "credit" ? "Yes" : "No",
              };

              switch (delivery.status) {
                case "review_and_assign_driver": {
                  this.tabItems.reviewAssignDriver.push(tmpObj);
                  break;
                }
                case "awaiting_delivery": {
                  this.tabItems.awaitingDelivery.push(tmpObj);
                  break;
                }
                case "in_transit": {
                  this.tabItems.inTransit.push(tmpObj);
                  break;
                }
                case "awaiting_payment":
                case "delivered": {
                  this.tabItems.deliveredAndComplete.push(tmpObj);
                  break;
                }
                case "undelivered":
                case "delivery_rescheduled": {
                  this.tabItems.notDelivered.push(tmpObj);
                  break;
                }
                case "cancelled":
                case "cancelled_process_refund_and_restore_stock":
                case "cancelled_restore_stock": {
                  this.tabItems.cancelled.push(tmpObj);
                  break;
                }
              }
            }

            this.setPaginationDetails(result.data);
            this.updateTabItemTotals();
            this.correctItemOrder();
          })
          .catch((error) => {
            this.handleHttpError(
              error,
              "An error occurred while loading deliveries."
            );
          })
          .finally(() => {
            this.paginationDetails.loading = false;
          });
      } else {
        this.paginationDetails.loading = true;

        this.$http
          .post(
            url,
            this.getSearchFilterParams,
            this.sessionStore.getHttpConfig
          )
          .then((result) => {
            this.items.splice(0);
            this.fullItems.splice(0);
            this.tabItems.reviewAssignDriver.splice(0);
            this.tabItems.awaitingDelivery.splice(0);
            this.tabItems.inTransit.splice(0);
            this.tabItems.deliveredAndComplete.splice(0);
            this.tabItems.notDelivered.splice(0);
            this.tabItems.cancelled.splice(0);

            for (let i = 0; i < result.data.data.length; i++) {
              const delivery = result.data.data[i];
              this.fullItems.push(delivery);

              const tempTotal =
                Number(delivery.order.total) +
                Number(delivery.order.delivery_fee);
              const total =
                Math.round((tempTotal + Number.EPSILON) * 100) / 100;

              const collectedTotal =
                Math.round(
                  (Number(delivery.order.collected_total) + Number.EPSILON) *
                    100
                ) / 100;

              let driverName = "N/A";
              if (delivery.driver && delivery.driver.user) {
                driverName = `${delivery.driver.user.firstname} ${delivery.driver.user.lastname}`;
              }

              const tmpObj = {
                id: delivery.id,
                orderReference: delivery.order.order_reference,
                shop: delivery.order.shop.name,
                value: total.toString(),
                collectedValue: collectedTotal.toString(),
                deliveryDate: delivery.delivery_date,
                status: this.deliveryStatuses.find(
                  (element) => element.key == delivery.status
                ).value,
                payment_type: delivery.order.payment_type,
                driver: driverName,
                isCreditOrder: delivery.order.tags === "credit" ? "Yes" : "No",
              };

              switch (delivery.status) {
                case "review_and_assign_driver": {
                  this.tabItems.reviewAssignDriver.push(tmpObj);
                  break;
                }
                case "awaiting_delivery": {
                  this.tabItems.awaitingDelivery.push(tmpObj);
                  break;
                }
                case "in_transit": {
                  this.tabItems.inTransit.push(tmpObj);
                  break;
                }
                case "awaiting_payment":
                case "delivered": {
                  this.tabItems.deliveredAndComplete.push(tmpObj);
                  break;
                }
                case "undelivered":
                case "delivery_rescheduled": {
                  this.tabItems.notDelivered.push(tmpObj);
                  break;
                }
                case "cancelled":
                case "cancelled_process_refund_and_restore_stock":
                case "cancelled_restore_stock": {
                  this.tabItems.cancelled.push(tmpObj);
                  break;
                }
              }
            }

            this.setPaginationDetails(result.data);
            this.updateTabItemTotals();
            this.correctItemOrder();
          })
          .catch((error) => {
            this.handleHttpError(
              error,
              "An error occurred while loading deliveries."
            );
          })
          .finally(() => {
            this.paginationDetails.loading = false;
          });
      }
    },

    searchByValue() {
      if (this.getSearchFilterParams.length === 0) {
        this.fetchDataByUrl("/deliveries?size=200");
        return;
      }

      this.baseUrl = "/deliveries/search?size=200";

      this.$http
        .post(
          this.baseUrl,
          this.getSearchFilterParams,
          this.sessionStore.getHttpConfig
        )
        .then((result) => {
          this.items.splice(0);
          this.fullItems.splice(0);
          this.tabItems.reviewAssignDriver.splice(0);
          this.tabItems.awaitingDelivery.splice(0);
          this.tabItems.inTransit.splice(0);
          this.tabItems.deliveredAndComplete.splice(0);
          this.tabItems.notDelivered.splice(0);
          this.tabItems.cancelled.splice(0);

          for (let i = 0; i < result.data.data.length; i++) {
            const delivery = result.data.data[i];
            this.fullItems.push(delivery);

            const tempTotal =
              Number(delivery.order.total) +
              Number(delivery.order.delivery_fee);
            const total = Math.round((tempTotal + Number.EPSILON) * 100) / 100;

            const collectedTotal =
              Math.round(
                (Number(delivery.order.collected_total) + Number.EPSILON) * 100
              ) / 100;

            let driverName = "N/A";
            if (delivery.driver && delivery.driver.user) {
              driverName = `${delivery.driver.user.firstname} ${delivery.driver.user.lastname}`;
            }

            const tmpObj = {
              id: delivery.id,
              orderReference: delivery.order.order_reference,
              shop: delivery.order.shop.name,
              value: total.toString(),
              collectedValue: collectedTotal.toString(),
              deliveryDate: delivery.delivery_date,
              status: this.deliveryStatuses.find(
                (element) => element.key == delivery.status
              ).value,
              payment_type: delivery.order.payment_type,
              driver: driverName,
              isCreditOrder: delivery.order.tags === "credit" ? "Yes" : "No",
            };

            switch (delivery.status) {
              case "review_and_assign_driver": {
                this.tabItems.reviewAssignDriver.push(tmpObj);
                break;
              }
              case "awaiting_delivery": {
                this.tabItems.awaitingDelivery.push(tmpObj);
                break;
              }
              case "in_transit": {
                this.tabItems.inTransit.push(tmpObj);
                break;
              }
              case "awaiting_payment":
              case "delivered": {
                this.tabItems.deliveredAndComplete.push(tmpObj);
                break;
              }
              case "undelivered":
              case "delivery_rescheduled": {
                this.tabItems.notDelivered.push(tmpObj);
                break;
              }
              case "cancelled":
              case "cancelled_process_refund_and_restore_stock":
              case "cancelled_restore_stock": {
                this.tabItems.cancelled.push(tmpObj);
                break;
              }
            }
          }

          this.setPaginationDetails(result.data);
          this.updateTabItemTotals();
          this.correctItemOrder();
        })
        .catch((error) => {
          console.error("Error: ", error);
          this.$notify.success("An error occurred while searching orders.");
        });
    },

    setTabHeadings() {
      this.tabHeadings.splice(0);

      if (
        this.sessionStore.user.roles.includes("super_admin") ||
        this.sessionStore.user.roles.includes("admin") ||
        this.sessionStore.user.roles.includes("agent") ||
        this.sessionStore.user.roles.includes("warehouse_manager")
      ) {
        this.tabHeadings.push({
          id: "reviewAssignDriver",
          title: "Review & Assign Driver",
          anchor: "review_and_assign_driver",
          icon: "cart-arrow-right",
          enableActionEdit: true,
          enableActionDeactivate: false,
          enableActionDelete: false,
          enableActionGoToItemPage: false,
          enableActionApprove: true,
        });
      }

      if (
        this.sessionStore.user.roles.includes("super_admin") ||
        this.sessionStore.user.roles.includes("admin") ||
        this.sessionStore.user.roles.includes("agent") ||
        this.sessionStore.user.roles.includes("warehouse_manager") ||
        this.sessionStore.user.roles.includes("driver")
      ) {
        this.tabHeadings.push({
          id: "awaitingDelivery",
          title: "Awaiting Delivery",
          anchor: "awaiting_delivery",
          icon: "cart-arrow-right",
          enableActionEdit: !this.sessionStore.user.roles.includes("driver"),
          enableActionDeactivate: false,
          enableActionDelete: false,
          enableActionGoToItemPage: false,
          enableActionApprove: this.sessionStore.user.roles.includes("driver"),
          approveTooltip: "Ship Order",
          approveIcon: "ni-delivery-fast",
          approveConfirmationText: "Are you sure you want to ship this order?",
        });

        this.tabHeadings.push({
          id: "inTransit",
          title: "In Transit",
          anchor: "in_transit",
          icon: "cart-arrow-right",
          enableActionEdit: true,
          enableActionDeactivate: false,
          enableActionDelete: false,
          enableActionGoToItemPage: false,
          enableActionApprove: false,
        });

        this.tabHeadings.push({
          id: "deliveredAndComplete",
          title: "Delivered",
          anchor: "delivered",
          icon: "cart-arrow-right",
          enableActionEdit: this.sessionStore.user.roles.includes(
            "super_admin"
          ),
          enableActionDeactivate: false,
          enableActionDelete: false,
          enableActionGoToItemPage: false,
          enableActionApprove: false,
        });
      }

      if (
        this.sessionStore.user.roles.includes("super_admin") ||
        this.sessionStore.user.roles.includes("admin") ||
        this.sessionStore.user.roles.includes("agent") ||
        this.sessionStore.user.roles.includes("warehouse_manager")
      ) {
        this.tabHeadings.push({
          id: "notDelivered",
          title: "Not Delivered",
          anchor: "not_delivered",
          icon: "cart-arrow-right",
          enableActionEdit: true,
          enableActionDeactivate: false,
          enableActionDelete: false,
          enableActionGoToItemPage: false,
          enableActionApprove: false,
        });

        this.tabHeadings.push({
          id: "cancelled",
          title: "Cancelled",
          anchor: "cancelled",
          icon: "cart-arrow-right",
          enableActionEdit: false,
          enableActionDeactivate: false,
          enableActionDelete: false,
          enableActionGoToItemPage: false,
          enableActionApprove: false,
        });
      }
    },

    updateTabItemTotals() {
      for (const [key, value] of Object.entries(this.tabItems)) {
        let totalSum = 0;
        let collectedSum = 0;
        value.forEach((item) => {
          if (
            item.payment_type === "cash_on_delivery" &&
            item.isCreditOrder === "No"
          ) {
            totalSum += Number(item.value);
            collectedSum += Number(item.collectedValue);
          }
        });

        const totalVal = Math.round((totalSum + Number.EPSILON) * 100) / 100;
        this.tabItemsTotals[key] = totalVal.toString();

        const collectedVal =
          Math.round((collectedSum + Number.EPSILON) * 100) / 100;
        this.tabItemsTotalsCollected[key] = collectedVal.toString();
      }
    },

    updateItemsPerPage(newValue) {
      const searchParams = new URLSearchParams({
        size: newValue,
      });

      const url = `${this.paginationDetails.path}?${searchParams.toString()}`;

      this.fetchDataByUrl(url);
    },

    clearFilters() {
      this.filterByShopParams.value = null;
      this.filterByDriverParams.value = null;
      this.filterByDateParams.value = null;
      this.searchByValue();
    },

    updateSearchParameters(type, val) {
      if (type === "driver") {
        this.filterByDriverParams.value = val;
      }

      if (type === "date") {
        this.filterByDateParams.value = val;
      }

      this.searchByValue();
    },

    updateShopSearchParam(val) {
      this.filterByShopParams.value = val;
      this.searchByValue();
    },

    correctItemOrder() {
      this.tabItems;

      for (const [key] of Object.entries(this.tabItems)) {
        this.tabItems[key].sort((a, b) => {
          const dateA = new Date(a.deliveryDate).getTime();
          const dateB = new Date(b.deliveryDate).getTime();
          return dateA > dateB ? 1 : -1;
        });
      }
    },
  },
  mounted() {
    this.setTabHeadings();

    this.fetchDataByUrl("/deliveries?size=200");

    this.dataLoadInterval = setInterval(() => {
      this.fetchDataByUrl(this.baseUrl);
    }, 15000);

    this.$http
      .get("/drivers?size=200", this.sessionStore.getHttpConfig)
      .then((result) => {
        const drivers = result.data.data;

        this.driverList.splice(0);
        this.driverUserList.splice(0);
        for (let i = 0; i < drivers.length; i++) {
          this.driverList.push(drivers[i]);
          this.driverUserList.push({
            driverId: drivers[i].id,
            userId: drivers[i].user.id,
            friendlyName: `${drivers[i].user.firstname} ${drivers[i].user.lastname}`,
          });
        }
      })
      .catch((error) => {
        this.handleHttpError(
          error,
          "An error occurred while loading driver details. Please try again later or contact support."
        );
      });
  },
  beforeDestroy() {
    clearInterval(this.dataLoadInterval);
  },
};
</script>
